import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { Page } from '../components/page';

import { RichText } from '../components/richt_text';
import { services } from '../data/services';

import './fachgebiete.scss';

const ServicesPage: React.FunctionComponent = () => {
  return (
    <Page title="Fachgebiete">
      <RichText>
        <h1>Unsere Fachgebiete</h1>
        <div className="service_wrapper">
          <Grid container spacing={3}>
            {services.map((service, index) => {
              return (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <Paper className="service">
                    {service.icon}
                    <div className="description">
                      <b>{service.title}</b>
                      <br />
                      {service.description && <p>{service.description}</p>}
                    </div>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </RichText>
    </Page>
  );
};

export default ServicesPage;
